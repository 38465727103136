import { SVGProps, Ref, forwardRef } from 'react'

const SvgLifeStories = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M23.224 10.5a10.5 10.5 0 1 0-19.391 6.275L.75 23.25l6.475-3.084c.563.35 1.157.646 1.775.884"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m17.25 23.25-5.114-5.335a3.025 3.025 0 0 1-.567-3.492v0a3.026 3.026 0 0 1 4.846-.786l.835.835.835-.835a3.026 3.026 0 0 1 4.846.786v0a3.025 3.025 0 0 1-.567 3.492L17.25 23.25Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgLifeStories)
export default ForwardRef
